<template>
  <div class="home">
    <nav-tab></nav-tab>
    
    <keep-alive>
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
    <!-- <kefu></kefu> -->
    <foot-item></foot-item>
  </div>
</template>

<script>
// @ is an alias to /src
import NavTab from '@/components/navTab/NavTab.vue'

import FootItem from './common/footItem.vue'
import Kefu from './common/kefu.vue'
export default {
  name: 'HomeView',
  components: {
    NavTab,
    FootItem,
    Kefu
  }
}
</script>
