<template>
  <div>
    <div class="footer">
      <div class="row">
        <div class="box">
          <div class="info">
            <div class="top">
              <div class="pic">
                <img src="@/assets/img/uploads/erweima.jpg" alt="" />
              </div>
              <div class="phone">
                <p>扫一扫二维码享积分</p>
                <p class="date">
                  <!-- <span>24</span> -->
                  服务热线</p>
                <p>0991-8802091<br>
                  15099066600
                </p>
  
              </div>
             
            </div>
            <div class="bottom">
              <p>日常消费积分抵扣物业费，1积分 = 1元物业费</p>
              <p class="title">——新物连</p>
            </div> 
          </div>
        </div>
        <div class="address">
          <p>
            <img class="icon" src="../../assets/img/uploads/foot/02.jpg" alt="">
          <span>地址：新疆乌鲁木齐市沙依巴克区钱塘江路阳光100A座22楼</span>

          </p>
          
          <p>
            <img class="icon" src="../../assets/img/uploads/foot/01.jpg" alt="">

            <span>电话：0991-8802091</span>
          </p>
          <p>
            <img class="icon" src="../../assets/img/uploads/foot/03.jpg" alt="">

            <span>邮箱：171783916@qq.com</span>
          </p>
        </div>
      </div>
      <hr />
      <div class="banquan">
        <span>版权所有©️：新疆新物连科技服务有限公司</span><br>
        <a href="https://beian.miit.gov.cn/" target="_blank">新ICP备2023004272号-2</a >
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
.footer {
  width: 100% !important;
}
.row{
  display:flex;
  justify-content: center;
}
.row .box{
  margin-top: -60px;
  background-color: #26282c;
  padding: 20px 0 20px 20px;

}
.row .info {
 
  padding: 20px;
  border-right: 1px solid #118ffa;
  border-bottom: 1px solid #118ffa;

}
.row .info .top{
  display: flex;
}

.row .info .top .phone{
	display: flex;
  flex-flow: column;
  justify-content: space-between;
	font-family: AdobeHeitiStd-Regular;
	font-size: 24px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #ffffff;
  margin-left: 20px;
}


.row .info .top .phone .date span{
  font-family: SourceHanSansCN-Regular;
	font-size: 72px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #ffffff;
}
.row .info .top .pic img{
  width: 178px;
  height: 178px;
}
.row .info .bottom{
  height: 65px;
  margin-top: 20px;
  padding: 5px;
  background-color: #118ffa;
  font-family: AdobeHeitiStd-Regular;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #ffffff;
}

.row .info .bottom p{
  margin:0;
  padding: 0;
}
.row .info .bottom  .title{
  text-align: right;
}
.address p{
  padding: 20px;
}
.address p .icon{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.address p span{
  padding-left: 10px;
  border-left: 1px solid #8e8e8e;

}


.banquan {
  text-align: center;
}

</style>
