import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'

// 引入bootstrap样式
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/index.css'

// 全局注册 $
// eslint-disable-next-line no-undef
Vue.prototype.$ = $
Vue.config.productionTip = false

// main.js
 
// 禁用右键
// document.addEventListener('contextmenu', function(e) {
//   e.preventDefault();
// });
 
// 禁用F12
// document.addEventListener('keydown', function(e) {
//   if (e.key === 'F12') {
//     e.preventDefault();
//   }
// });
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
