<template>
    <div class="nav">

        <div class="header">
         
          <div class="logo">
            <h1><img src="../../assets/img/logo.png" alt=""></h1>
          </div> 
          <div class="name">
            <h1>新疆新物连科技服务有限公司</h1>
          </div>
          <div class="phone">
            <div class="left">
            <img src="../../assets/img/header/phone.jpg" alt="">
            </div>
            <div class="right">
              <span>全国服务热线</span><br>
              <span class="number">150-9906-6600

                
              </span>
            </div>
          </div>
        </div>
        <div class="bottom">
          <ul class="">
            <li
              :class="{ active: selectedItem === index }"
              v-for="(item, index) in list"
              :key="item.path"
              @click="handleItemClick(index)"
            >
              <router-link :to="item.path">{{ item.name }}</router-link>
            </li>
          </ul>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      // selectedItem: '#/profile',
      selectedItem: 0,
      list: [
        {
          path: '/index',
          name: '首页'
        },
        {
          path: '/product',
          name: '项目详情'
        },
        {
          path: '/service',
          name: '平台服务'
        },
       
        
       
        {
          path: '/flow',
          name: '平台流程'
        },
        {
          path: '/company',
          name: '合作伙伴'
        },
        {
          path: '/activity',
          name: '公益活动'
        },
      ]
    }
  },
  methods: {
    // handleItemClick(item) {
    //   this.selectedItem = item.view.location.hash
    // }
    handleItemClick(index) {
       this.selectedItem = index

    }
  }
}
</script>

<style scoped>
.nav{
  width: 100%;
  height: auto;
  text-align: center;
 
}
.header{
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .logo img{
  width: 110px;
  height: 110px;
}
.header .name {
  padding:0 31px;
  border-left: 1px  solid rgba(0, 0, 0, 0.2);
}
.header .phone{
  display: flex;
  align-items: center;
  margin-left: 100px;
}
.header .phone img{
	width: 26px;
	height: 33px;

}
.header .phone .right{
  margin-left: 5px;
  text-align: left;
	font-family: AdobeHeitiStd-Regular;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #333333;
}
.header .phone .right .number{
 
	font-family: AdobeHeitiStd-Regular;
	font-size: 25px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #23a5f9;
}
.bottom{
  border-top:1px solid rgba(0, 0, 0, 0.1) ;
  background-color: #fff;
 
}
.bottom  ul{
  margin: 0;
}
.bottom  ul li{
  display:inline-block;
  padding: 20px;
  text-align: center;
  
}
.bottom  ul li a{
  color: #000;
}
.active{
  background-color:#23a5f9 ;
}
</style>
