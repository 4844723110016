import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'index'
  },
  // 首页
  {
    path: '/index',
    component: () => import('@/views/ProfileView.vue')
  },
  
     // 项目详情
  {
    path: '/product',
    component: () => import('@/views/productView.vue')
  },
  // 合作商家 
  {
    path: '/company',
    component: () => import('@/views/CompanyView.vue')
  },
  // 平台服务
  {
    path: '/service',
    component: () => import('@/views/entryView.vue')
  },
  //公益活动
  {
    path: '/activity',
    component: () => import('@/views/albumView.vue')
  },
  // 流程
  {
    path: '/flow',
    component: () => import('@/views/contactView.vue')
  },  
]

const router = new VueRouter({
  routes
})

export default router
