<template>
    <div class="content">
       <div class="kefu">
        <a href="https://work.weixin.qq.com/kfid/kfcf6f0f54db4e13f48">
            在线咨询
        </a>
        <img class="img" src="../../assets/img/客服二维码.png" />
       </div>
  </div>


       
    
</template>
<script>
export default {
    name: 'Kefu'
}
    
        
  
</script>
<style scoped>
.content{
    position: absolute;
    right: 0;
    top: 50%;
}
.kefu{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.kefu .img{
    width:150px;
    height:150px;
}
</style>